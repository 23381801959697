import { Container, Nav, Navbar, NavDropdown, Offcanvas } from "react-bootstrap";
import * as Styled from "./Header.styled";
import { useState } from "react";

const Header = () => {
    const collapseMenuBreakpoint = "sm";

    return (
        <Styled.HeaderContainer>
            <Navbar expand={collapseMenuBreakpoint} className="ml-auto">
                <Container fluid>
                    <Navbar.Brand href="/">
                        <Styled.Title>Thu Le </Styled.Title>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${collapseMenuBreakpoint}`} />
                    <Navbar.Offcanvas
                        id={`offcanvasNavbar-expand-${collapseMenuBreakpoint}`}
                        aria-labelledby={`offcanvasNavbarLabel-expand-${collapseMenuBreakpoint}`}
                        placement="end"
                    >
                        <Styled.OffcanvasHeaderContainer>
                            <Offcanvas.Header closeButton />
                        </Styled.OffcanvasHeaderContainer>
                        <Styled.OffcanvasBodyContainer>
                            <Offcanvas.Body>
                                <Nav className="justify-content-end flex-grow-1 pe-3">
                                    <Nav.Link href="/">Home</Nav.Link>
                                    <NavDropdown
                                        title="Gallery"
                                        id={`offcanvasNavbarDropdown-expand-${collapseMenuBreakpoint}`}
                                        renderMenuOnMount={true}
                                    >
                                        <NavDropdown.Item href="/gallery/children">Children</NavDropdown.Item>
                                        <NavDropdown.Item href="/gallery/family">Family</NavDropdown.Item>
                                        <NavDropdown.Item href="/gallery/food-product">
                                            Food and Product
                                        </NavDropdown.Item>
                                    </NavDropdown>
                                    <Nav.Link href="/about">About</Nav.Link>
                                    <Nav.Link href="/contact">Contact</Nav.Link>
                                </Nav>
                            </Offcanvas.Body>
                        </Styled.OffcanvasBodyContainer>
                    </Navbar.Offcanvas>
                </Container>
            </Navbar>
        </Styled.HeaderContainer>
    );
};

export default Header;
