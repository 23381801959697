import * as Styled from "./ImageGrid.styled";
import { Grid } from "../../types/ImageTypes";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { Fragment, ReactElement, ReactNode } from "react";

interface Props {
    imageGrid: Grid;
}

interface ConditionWrapperProps {
    condition: boolean;
    wrapper: Function;
    children: ReactNode;
}

const ConditionalWrapper = ({ condition, wrapper, children }: ConditionWrapperProps) =>
    condition ? wrapper(children) : children;

const ImageGrid = ({ imageGrid }: Props) => {
    return (
        <Styled.PhotoGridImageGallery>
            {imageGrid.columns.map((column) => (
                <Styled.PhotoGridColumn shouldBeVerticallyExpanded={imageGrid.shouldBeVerticallyExpanded}>
                    {column.map(({ src, title, link }) => (
                        <Styled.PhotoGridItem>
                            <ConditionalWrapper
                                condition={!!link}
                                wrapper={(children: ReactElement) => <a href={link}>{children}</a>}
                            >
                                <Fragment>
                                    <LazyLoadImage alt={title} effect="blur" src={src} />
                                    {title && (
                                        <Styled.PhotoGridItemOverlay>
                                            <span>{title}</span>
                                        </Styled.PhotoGridItemOverlay>
                                    )}
                                </Fragment>
                            </ConditionalWrapper>
                        </Styled.PhotoGridItem>
                    ))}
                </Styled.PhotoGridColumn>
            ))}
        </Styled.PhotoGridImageGallery>
    );
};

export default ImageGrid;
