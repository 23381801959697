import { Image } from "react-bootstrap";
import ImageCollectionGrid, { PRODUCT_GALLERY_COLLECTION } from "../../ImageCollections/ImageCollection";
import QuoteCard from "../../QuoteCard/QuoteCard";

const ProductGallery = () => {
    return (
        <>
            <Image src={PRODUCT_GALLERY_COLLECTION.slide[0].src} fluid />
            <QuoteCard text="Food and Product" isUppercase />
            <ImageCollectionGrid collection={PRODUCT_GALLERY_COLLECTION} />
        </>
    );
};

export default ProductGallery;
