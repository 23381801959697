import { Image } from "react-bootstrap";

import QuoteCard from "../../QuoteCard/QuoteCard";
import ImageCollectionGrid, { CHILDREN_GALLERY_COLLECTION } from "../../ImageCollections/ImageCollection";

const ChildrenGallery = () => {
    return (
        <>
            <Image src={CHILDREN_GALLERY_COLLECTION.slide[0].src} fluid />
            <QuoteCard text="Children" isUppercase />
            <ImageCollectionGrid collection={CHILDREN_GALLERY_COLLECTION} />
        </>
    );
};

export default ChildrenGallery;
