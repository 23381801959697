import { Image } from "react-bootstrap";
import ImageCollectionGrid, { FAMILY_GALLERY_COLLECTION } from "../../ImageCollections/ImageCollection";
import QuoteCard from "../../QuoteCard/QuoteCard";

const FamilyGallery = () => {
    return (
        <>
            <Image src={FAMILY_GALLERY_COLLECTION.slide[0].src} fluid />
            <QuoteCard text="Family" isUppercase />
            <ImageCollectionGrid collection={FAMILY_GALLERY_COLLECTION} />
        </>
    );
};

export default FamilyGallery;
