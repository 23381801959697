import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Home from "./Pages/Home/Home";
import ChildrenGallery from "./Pages/Gallery/ChildrenGallery";
import FamilyGallery from "./Pages/Gallery/FamilyGallery";
import ProductGallery from "./Pages/Gallery/ProductGallery";
import About from "./Pages/About/About";
import Contact from "./Pages/Contact/Contact";
import * as Styled from "./PageContent.styled";

export const PageContent = () => {
    return (
        <Styled.Body>
            <Header />
            <Styled.MainContent>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="gallery/children" element={<ChildrenGallery />} />
                    <Route path="gallery/family" element={<FamilyGallery />} />
                    <Route path="gallery/food-product" element={<ProductGallery />} />
                    <Route path="about" element={<About />} />
                    <Route path="contact" element={<Contact />} />
                    {/* default redirect to home page */}
                    <Route path="*" element={<Navigate to="/" />} />
                </Routes>
            </Styled.MainContent>
            <Footer />
        </Styled.Body>
    );
};

export default PageContent;
