import HomeGrid11 from "../../assets/images/home/grid/grid-1/grid-1-1.jpg";
import HomeGrid12 from "../../assets/images/home/grid/grid-1/grid-1-2.jpg";
import HomeSlide1 from "../../assets/images/home/slide/slide-1.jpg";
import HomeSlide2 from "../../assets/images/home/slide/slide-2.jpg";
import HomeSlide3 from "../../assets/images/home/slide/slide-3.jpg";
import HomeSlide4 from "../../assets/images/home/slide/slide-4.jpg";
import HomeSlide5 from "../../assets/images/home/slide/slide-5.jpg";
import HomeSlide6 from "../../assets/images/home/slide/slide-6.jpg";

import ChildrenGallerySlide1 from "../../assets/images/gallery/children/slide/slide-1.jpg";
import ChildrenGalleryGrid111 from "../../assets/images/gallery/children/grid/grid-1/grid-1-1.jpg";
import ChildrenGalleryGrid112 from "../../assets/images/gallery/children/grid/grid-1/grid-1-2.jpg";
import ChildrenGalleryGrid121 from "../../assets/images/gallery/children/grid/grid-1/grid-2-1.jpg";
import ChildrenGalleryGrid122 from "../../assets/images/gallery/children/grid/grid-1/grid-2-2.jpg";
import ChildrenGalleryGrid131 from "../../assets/images/gallery/children/grid/grid-1/grid-3-1.jpg";
import ChildrenGalleryGrid132 from "../../assets/images/gallery/children/grid/grid-1/grid-3-2.jpg";
import ChildrenGalleryGrid211 from "../../assets/images/gallery/children/grid/grid-2/grid-1-1.jpg";
import ChildrenGalleryGrid221 from "../../assets/images/gallery/children/grid/grid-2/grid-2-1.jpg";
import ChildrenGalleryGrid222 from "../../assets/images/gallery/children/grid/grid-2/grid-2-2.jpg";
import ChildrenGalleryGrid231 from "../../assets/images/gallery/children/grid/grid-2/grid-3-1.jpg";
import ChildrenGalleryGrid311 from "../../assets/images/gallery/children/grid/grid-3/grid-1-1.jpg";
import ChildrenGalleryGrid321 from "../../assets/images/gallery/children/grid/grid-3/grid-2-1.jpg";
import ChildrenGalleryGrid331 from "../../assets/images/gallery/children/grid/grid-3/grid-3-1.jpg";

import FamilyGallerySlide1 from "../../assets/images/gallery/family/slide/slide-1.jpg";
import FamilyGalleryGrid111 from "../../assets/images/gallery/family/grid/grid-1/grid-1-1.jpg";
import FamilyGalleryGrid112 from "../../assets/images/gallery/family/grid/grid-1/grid-1-2.jpg";
import FamilyGalleryGrid121 from "../../assets/images/gallery/family/grid/grid-1/grid-2-1.jpg";
import FamilyGalleryGrid211 from "../../assets/images/gallery/family/grid/grid-2/grid-1-1.jpg";
import FamilyGalleryGrid221 from "../../assets/images/gallery/family/grid/grid-2/grid-2-1.jpg";
import FamilyGalleryGrid311 from "../../assets/images/gallery/family/grid/grid-3/grid-1-1.jpg";
import FamilyGalleryGrid321 from "../../assets/images/gallery/family/grid/grid-3/grid-2-1.jpg";
import FamilyGalleryGrid411 from "../../assets/images/gallery/family/grid/grid-4/grid-1-1.jpg";
import FamilyGalleryGrid412 from "../../assets/images/gallery/family/grid/grid-4/grid-1-2.jpg";
import FamilyGalleryGrid413 from "../../assets/images/gallery/family/grid/grid-4/grid-1-3.jpg";
import FamilyGalleryGrid421 from "../../assets/images/gallery/family/grid/grid-4/grid-2-1.jpg";
import FamilyGalleryGrid422 from "../../assets/images/gallery/family/grid/grid-4/grid-2-2.jpg";

import ProductGallerySlide1 from "../../assets/images/gallery/product/slide/slide-1.jpg";
import ProductGalleryGrid111 from "../../assets/images/gallery/product/grid/grid-1/grid-1-1.jpg";
import ProductGalleryGrid112 from "../../assets/images/gallery/product/grid/grid-1/grid-1-2.jpg";
import ProductGalleryGrid113 from "../../assets/images/gallery/product/grid/grid-1/grid-1-3.jpg";
import ProductGalleryGrid114 from "../../assets/images/gallery/product/grid/grid-1/grid-1-4.jpg";
import ProductGalleryGrid115 from "../../assets/images/gallery/product/grid/grid-1/grid-1-5.jpg";
import ProductGalleryGrid121 from "../../assets/images/gallery/product/grid/grid-1/grid-2-1.jpg";
import ProductGalleryGrid122 from "../../assets/images/gallery/product/grid/grid-1/grid-2-2.jpg";
import ProductGalleryGrid123 from "../../assets/images/gallery/product/grid/grid-1/grid-2-3.jpg";
import ProductGalleryGrid124 from "../../assets/images/gallery/product/grid/grid-1/grid-2-4.jpg";
import ProductGalleryGrid125 from "../../assets/images/gallery/product/grid/grid-1/grid-2-5.jpg";
import ProductGalleryGrid131 from "../../assets/images/gallery/product/grid/grid-1/grid-3-1.jpg";
import ProductGalleryGrid132 from "../../assets/images/gallery/product/grid/grid-1/grid-3-2.jpg";
import ProductGalleryGrid133 from "../../assets/images/gallery/product/grid/grid-1/grid-3-3.jpg";
import ProductGalleryGrid134 from "../../assets/images/gallery/product/grid/grid-1/grid-3-4.jpg";

import { ROUTES } from "../../constants/Routes";
import { ImageCollection } from "../../types/ImageTypes";
import * as Styled from "./ImageCollection.styled";
import ImageGrid from "../ImageGrid/ImageGrid";

export const HOME_COLLECTION: ImageCollection = {
    slide: [
        {
            src: HomeSlide1,
        },
        {
            src: HomeSlide2,
        },
        {
            src: HomeSlide3,
        },
        {
            src: HomeSlide4,
        },
        {
            src: HomeSlide5,
        },
        {
            src: HomeSlide6,
        },
    ],
    grids: [
        {
            columns: [
                [
                    {
                        src: HomeGrid11,
                        link: ROUTES.CHILDREN_GALLERY,
                        title: "Children",
                    },
                ],
                [
                    {
                        src: HomeGrid12,
                        link: ROUTES.FAMILY_GALLERY,
                        title: "Family",
                    },
                ],
            ],
        },
    ],
};

export const CHILDREN_GALLERY_COLLECTION: ImageCollection = {
    slide: [
        {
            src: ChildrenGallerySlide1,
        },
    ],
    grids: [
        {
            columns: [
                [
                    {
                        src: ChildrenGalleryGrid111,
                    },
                    {
                        src: ChildrenGalleryGrid112,
                    },
                ],
                [
                    {
                        src: ChildrenGalleryGrid121,
                    },
                    {
                        src: ChildrenGalleryGrid122,
                    },
                ],
                [
                    {
                        src: ChildrenGalleryGrid131,
                    },
                    {
                        src: ChildrenGalleryGrid132,
                    },
                ],
            ],
        },
        {
            columns: [
                [
                    {
                        src: ChildrenGalleryGrid211,
                    },
                ],
                [
                    {
                        src: ChildrenGalleryGrid221,
                    },
                    {
                        src: ChildrenGalleryGrid222,
                    },
                ],
                [
                    {
                        src: ChildrenGalleryGrid231,
                    },
                ],
            ],
        },
        {
            columns: [
                [
                    {
                        src: ChildrenGalleryGrid311,
                    },
                ],
                [
                    {
                        src: ChildrenGalleryGrid321,
                    },
                ],
                [
                    {
                        src: ChildrenGalleryGrid331,
                    },
                ],
            ],
        },
    ],
};

export const FAMILY_GALLERY_COLLECTION: ImageCollection = {
    slide: [
        {
            src: FamilyGallerySlide1,
        },
    ],
    grids: [
        {
            columns: [
                [
                    {
                        src: FamilyGalleryGrid111,
                    },
                    {
                        src: FamilyGalleryGrid112,
                    },
                ],
                [
                    {
                        src: FamilyGalleryGrid121,
                    },
                ],
            ],
        },
        {
            columns: [
                [
                    {
                        src: FamilyGalleryGrid211,
                    },
                ],
                [
                    {
                        src: FamilyGalleryGrid221,
                    },
                ],
            ],
        },
        {
            columns: [
                [
                    {
                        src: FamilyGalleryGrid311,
                    },
                ],
                [
                    {
                        src: FamilyGalleryGrid321,
                    },
                ],
            ],
        },
        {
            columns: [
                [
                    {
                        src: FamilyGalleryGrid411,
                    },
                    {
                        src: FamilyGalleryGrid412,
                    },
                    {
                        src: FamilyGalleryGrid413,
                    },
                ],
                [
                    {
                        src: FamilyGalleryGrid421,
                    },
                    {
                        src: FamilyGalleryGrid422,
                    },
                ],
            ],
        },
    ],
};

export const PRODUCT_GALLERY_COLLECTION: ImageCollection = {
    slide: [
        {
            src: ProductGallerySlide1,
        },
    ],
    grids: [
        {
            columns: [
                [
                    {
                        src: ProductGalleryGrid111,
                        link: ROUTES.FOOD_PRODUCT_GALLERY,
                    },
                    {
                        src: ProductGalleryGrid112,
                        link: ROUTES.FAMILY_GALLERY,
                    },
                    {
                        src: ProductGalleryGrid113,
                        link: ROUTES.FAMILY_GALLERY,
                    },
                    {
                        src: ProductGalleryGrid114,
                        link: ROUTES.FAMILY_GALLERY,
                    },
                    {
                        src: ProductGalleryGrid115,
                        link: ROUTES.FAMILY_GALLERY,
                    },
                ],
                [
                    {
                        src: ProductGalleryGrid121,
                        link: ROUTES.FOOD_PRODUCT_GALLERY,
                    },
                    {
                        src: ProductGalleryGrid122,
                        link: ROUTES.FAMILY_GALLERY,
                    },
                    {
                        src: ProductGalleryGrid123,
                        link: ROUTES.FAMILY_GALLERY,
                    },
                    {
                        src: ProductGalleryGrid124,
                        link: ROUTES.FAMILY_GALLERY,
                    },
                    {
                        src: ProductGalleryGrid125,
                        link: ROUTES.FAMILY_GALLERY,
                    },
                ],
                [
                    {
                        src: ProductGalleryGrid131,
                        link: ROUTES.FOOD_PRODUCT_GALLERY,
                    },
                    {
                        src: ProductGalleryGrid132,
                        link: ROUTES.FAMILY_GALLERY,
                    },
                    {
                        src: ProductGalleryGrid133,
                        link: ROUTES.FAMILY_GALLERY,
                    },
                    {
                        src: ProductGalleryGrid134,
                        link: ROUTES.FAMILY_GALLERY,
                    },
                ],
            ],
            shouldBeVerticallyExpanded: true,
        },
    ],
};

interface Props {
    collection: ImageCollection;
}

const ImageCollectionGrid = ({ collection }: Props) => {
    return (
        <Styled.GridContainer>
            {collection.grids.map((grid) => (
                <ImageGrid imageGrid={grid} />
            ))}
        </Styled.GridContainer>
    );
};

export default ImageCollectionGrid;
