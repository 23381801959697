import styled, { css } from "styled-components";

export const PhotoGridImageGallery = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;

    > * {
        flex: 1;
    }

    ${({ theme: { breakpoints } }) => css`
        @media (min-width: ${breakpoints.mdMin}px) {
            flex-direction: row;
        }

        @media (max-width: ${breakpoints.smMax}px) {
            gap: 10px;
        }
    `}
`;

export const PhotoGridColumn = styled.div<{ shouldBeVerticallyExpanded?: boolean }>`
    display: flex;
    flex-direction: column;
    ${(props) =>
        !props.shouldBeVerticallyExpanded &&
        css`
            flex: 1;
            justify-content: space-between;
        `};
    gap: 30px;

    ${({ theme: { breakpoints } }) => css`
        @media (max-width: ${breakpoints.smMax}px) {
            gap: 10px;
        }
    `}
`;

export const PhotoGridItem = styled.div`
    position: relative;

    a {
        width: 100%;
        height: 100%;
        z-index: 2;
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
`;

export const PhotoGridItemOverlay = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(57, 57, 57, 0.502);
    top: 0;
    left: 0;
    transform: scale(0);
    color: ${({ theme }) => `${theme.colors.White}`};
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Didot, serif;
    text-transform: uppercase;
    font-size: ${({ theme }) => `${theme.font.size.h1}px`};

    ${({ theme: { breakpoints } }) => css`
        @media (max-width: ${breakpoints.mdMax}px) {
            font-size: ${({ theme }) => `${theme.font.size.h4}px`};
        }

        @media (max-width: ${breakpoints.smMax}px) {
            font-size: ${({ theme }) => `${theme.font.size.h5}px`};
        }
    `}

    ${PhotoGridItem}:hover & {
        transform: scale(1);
    }
`;
