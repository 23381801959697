import React from "react";
import GlobalStyle from "./theme/globalStyle";
import "bootstrap/dist/css/bootstrap.min.css";
import Header from "./components/Header/Header";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Footer from "./components/Footer/Footer";
import Home from "./components/Pages/Home/Home";
import Gallery from "./components/Pages/Gallery/ChildrenGallery";
import Contact from "./components/Pages/Contact/Contact";
import About from "./components/Pages/About/About";
import { DEFAULT_THEME } from "./styles/theme";
import { ThemeProvider } from "styled-components";
import ChildrenGallery from "./components/Pages/Gallery/ChildrenGallery";
import FamilyGallery from "./components/Pages/Gallery/FamilyGallery";
import ProductGallery from "./components/Pages/Gallery/ProductGallery";
import PageContent from "./components/PageContent";

const App = () => {
    return (
        <ThemeProvider theme={DEFAULT_THEME}>
            <GlobalStyle />
            <BrowserRouter>
                <PageContent />
            </BrowserRouter>
        </ThemeProvider>
    );
};

export default App;
