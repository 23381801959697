import { Card } from "react-bootstrap";
import * as Styled from "./QuoteCard.styled";
interface Props {
    text: string;
    isUppercase?: boolean;
}

const QuoteCard = ({ text, isUppercase }: Props) => {
    return (
        <Styled.QuoteCard className="text-center" $isUppercase={isUppercase}>
            <Card.Body>
                <Card.Text>{text}</Card.Text>
            </Card.Body>
        </Styled.QuoteCard>
    );
};

export default QuoteCard;
